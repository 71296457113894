import React, { useState, useEffect, useContext } from "react";
import DataTable from "react-data-table-component";
import CircularProgress from "@material-ui/core/CircularProgress";
import NativeSelect from "@material-ui/core/NativeSelect";
import {
  /* getAuthenticatedHeaders, */ FormatAmount,
  formatCurrency,
} from "../common/functions";
import moment from "moment";
import Banner from "../../components/Banner";
import approve from "../../assets/images/approve.svg";
import cancel from "../../assets/images/cancel.svg";
import Button from "../../components/Button";
import _, { filter } from "lodash";
import axios from "axios";
import { FunctionContext } from "../../context/FuntionsContext";
import { AuthContext } from "../../context/AuthContext";
import ModalDescripcion from "../layouts/ModalDescripcion";
import { RiContactsBookUploadLine } from "react-icons/ri";
import { useHistory } from "react-router-dom";
import useGeolocation from "react-hook-geolocation";
import AxioInstance from "../../axios/AxiosConfig";
import variablesDomain from "../../helpers/variablesDomain";

const banksIds = { BANCOLOMBIA: 0, AVVILLAS: 12, BBVA: 3, DAVIVIENDA: 2 };
const NewRetirosRefactory = (props) => {
  const DEV = localStorage.getItem("DEV");

  const { latitude, longitude } = useGeolocation();
  const history = useHistory();
  const [state, setState] = useState(null);
  const [amountCheck, setAmountCheck] = useState(0);
  const [loadingCheck, setLoadingCheck] = useState(false);
  const [filtros, setFiltros] = useState(null);
  const [header, setHeader] = useState(true);
  const [page, setPage] = useState(1);
  const [newPerPage, setPerPage] = useState(10);
  const [totalRows, setTotalRows] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const [ResultsNequi, setResultsNequi] = useState({});
  const [showNequi, setShowNequi] = useState(false);

  const {
    reloadRetiros,
    setReloadRetiros,
    buscador,
    setBuscador,
    buscar,
    setRenderDownload,
    renderDownload,
    setBuscar,
    RenderUpdate,
    opcionRetiros,
  } = useContext(FunctionContext);
  const [numFilas, setNumFilas] = useState([]);
  const [listWithdraw, setListWithdraw] = useState([]);
  const [listWithdrawFilter, setListWithdrawFilter] = useState([]);
  const [activo, setActivo] = useState(true);
  const [pagination, setPagination] = useState(true);
  const [pending, setPending] = useState(true);
  const [data, setData] = useState([]);
  const [dispersion, setDispersion] = useState([]);
  const [dispersionAll, setDispersionAll] = useState([]);
  const [label, setLabel] = useState("");
  const [msn, setMsn] = useState(
    "Se deben seleccionar elementos para poder realizar la acción, No se han modificado elementos."
  );
  const [error, setError] = useState(false);
  const [errorWithdraws, setErrorWithdraws] = useState(false);
  const [isActiveFilter, setIsActiveFilter] = useState(true);
  const [statuWithdraw, setStatuWithdraw] = useState(
    window.location.hash === "#creados"
      ? 0
      : window.location.hash === "#fraude"
        ? 6
        : window.location.hash === "#enproceso"
          ? 1
          : window.location.hash === "#consignado"
            ? 3
            : window.location.hash === "#depositado"
              ? 2
              : window.location.hash === "#rechazado"
                ? 5
                : window.location.hash === "#inscritos"
                  ? 7
                  : window.location.hash === "#inscribiendo"
                    ? 11
                    : window.location.hash === "#otrosbancos"
                      ? 12
                      : window.location.hash === "#nequi"
                        ? 14
                        : window.location.hash === "#davivienda"
                          ? 15
                          : window.location.hash === "#bbva"
                            ? 16
                            : window.location.hash === "#trixel"
                              ? 17
                              : window.location.hash === "#bbvaach"
                                ? 18
                                : window.location.hash === "#grupoaval"
                                  ? 19
                                  : window.location.hash === "#bancolcreditt"
                                    ? 20
                                    : window.location.hash === "#bancolombia"
                                      ? 13
                                      : window.location.hash === "#sininscribir"
                                        ? 8
                                        : "0"
  );
  const [browserName, setBrowserName] = useState("");
  const [reloadData, setReloadData] = useState(false);
  const [showBanner, setShowBanner] = useState(false);
  const [showBannerDownload, setShowBannerDownload] = useState(false);

  const [infoWithdraws, setInfoWithdraws] = useState(null);
  const [toggledClearRows, setToggledClearRows] = useState(false);
  const [loadingGDE, setLoadingGDE] = useState(false);
  const { getAuthenticatedHeaders, others, isSuperUser } =
    useContext(AuthContext);


  const [bancos, setBancos] = useState("");
  const [copiar, setCopiar] = useState(false);
  const [idCopiado, setIdCopiado] = useState();
  const [dataModal, setDataModal] = useState([]);
  const [openModal, setOpenModal] = useState(false);
  const [filterBankName, setfilterBankName] = useState(null);
  const [showBannerCobre, setShowBannerCobre] = useState(false);
  const [descriptionBannerCobre, setdescriptionBannerCobre] = useState("");

  const { showFilters = true, permittedActions = true } = props
  // new variables

  const [dataList, setDataList] = useState([])
  const [pageNum, setPageNum] = useState(1)
  const [perPage, setperPage] = useState(10)
  const defaultFilters = { state: null, status_account: null, bank: null, username_trixel: null, bank_name: null, exclude_autopilot: null, dispersion_cobre: null, showColursCobru: null, filterBrand: variablesDomain.brand === null ? 'NONE,COLURS,EZY_TRANSFER' : ' ', withdraw_id: null, document: null, username: null }
  const [activeFilter, setActiveFilter] = useState({ filterName: "", filters: defaultFilters })
  const [activeFilterConsignados, setActiveFilterConsignados] = useState()


  useEffect(() => {
    applyFilterByName(window.location.hash)

  }, [RenderUpdate]);
  useEffect(() => {
    lasfilas();
  }, [totalRows]);





  const lasfilas = () => {
    if (totalRows <= 10) {
      setNumFilas([10]);
    } else if (totalRows <= 25) {
      setNumFilas([10, 25]);
    } else if (totalRows <= 25) {
      setNumFilas([10, 25]);
    } else if (totalRows <= 50) {
      setNumFilas([10, 25, 50]);
    } else if (totalRows <= 100) {
      setNumFilas([10, 25, 50, 100]);
    } else if (totalRows <= 200) {
      setNumFilas([10, 25, 50, 100, 200]);
    } else if (totalRows <= 500) {
      setNumFilas([10, 25, 50, 100, 200, 500]);
    } else if (totalRows <= 1000) {
      setNumFilas([10, 25, 50, 100, 200, 500, 1000]);
    } else if (totalRows <= 1500) {
      setNumFilas([10, 25, 50, 100, 200, 500, 1000, 1500]);
    } else if (totalRows <= 2000) {
      setNumFilas([10, 25, 50, 100, 200, 500, 1000, 1500, 2000]);
    } else {
      setNumFilas([10, 25, 50, 100, 200, 500, 1000, 1500, 2000]);
    }
  };
  function esTextoONumero(valor) {
    return isNaN(valor);
  }
  useEffect(() => {
    if (esTextoONumero(buscador)) {
      window.alert("El campo solo acepta números");
    } else {
      getSearch();
    }
  }, [buscar]);

  useEffect(() => {
    if (copiar == true) {
      const timer = setTimeout(() => {
        setCopiar(false);
      }, 2000);
    }
  }, [copiar]);

  useEffect(() => {
    if (toggledClearRows == true) {
      setToggledClearRows(false);
    }
  }, [toggledClearRows]);

  useEffect(() => {
    if (label === "bulk_deposit" || label === "bulk_consigned") {
      updateStatusWithdraw(data);
    } else if (label === "bulk_reject" && bancos != "") {
      updateStatusWithdrawReject(data);
    }
  }, [bancos]);

  useEffect(() => {
    applyFilterByName(window.location.hash)
  }, [reloadRetiros]);

  useEffect(() => {
    if (data) {
      SumaCheck();
    }
  }, [data]);

  useEffect(() => {
    getBrowserInfo();
  }, []);



  const getBrowserInfo = async () => {
    if (
      (navigator.userAgent.indexOf("Opera") ||
        navigator.userAgent.indexOf("OPR")) != -1
    ) {
      setBrowserName("Opera");
    } else if (navigator.userAgent.indexOf("Chrome") != -1) {
      setBrowserName("Chrome");
    } else if (navigator.userAgent.indexOf("Safari") != -1) {
      setBrowserName("Safari");
    } else if (navigator.userAgent.indexOf("Firefox") != -1) {
      setBrowserName("Firefox");
    } else if (
      navigator.userAgent.indexOf("MSIE") != -1 ||
      !!document.documentMode == true
    ) {
      setBrowserName("IE");
    } else {
      setBrowserName("Unknown");
    }
  };
  const SumaCheck = () => {
    setLoadingCheck(true);

    let total = 0;

    data.map((item) => {
      try {
        const payedAmount = item.payed_amount; // Eliminamos comas si es necesario
        total += payedAmount;
      } catch (error) {
        console.error("Error al convertir y sumar:", error);
      }
    });

    setLoadingCheck(false);

    setAmountCheck(total);
  };

  const getFilterBrand = () => {
    return window.location.hash === "#ezytransfer" ? 'EZY_TRANSFER' : false
  }
  const filterBrand = getFilterBrand()



  const getSearch = async () => {
    if (buscador.length !== 0) {
      var withdraw_id = "";
      var document = "";
      var username = "";

      if (opcionRetiros == 1) {
        withdraw_id = buscador;
      } else if (opcionRetiros == 3) {
        username = buscador;
      } else {
        document = buscador;
      }

      try {
        const filterSearch = {
          ...defaultFilters,
          withdraw_id: withdraw_id,
          document: document,
          username: username,
          filterBrand: 'NONE,COLURS,EZY_TRANSFER'
        }

        fetchData(filterSearch, 1, 99999999)


      } catch (error) {
        console.log("🚀 ~ getSearch ~ error:", error)

      }
    }
  };






  const cHangeName = (item) => {
    let name = "";
    if (item.type === "Retiro a tercero") {
      if (item.account_holder_name && item.owner_first_name) {
        name = ` ${item.owner_first_name}`;
      } else {
        name = item.owner_first_name;
      }
      //name = item.account_holder_name ? item.account_holder_name + ' - ' + item.owner_first_name ? item.owner_first_name : '' :''
    } else if (item.type === "Retiro") {
      /* if (item.account_holder_name && item.payer_id ) {
                name = `${item.account_holder_name} - ${item.owner_first_name}`
            } else {
                name = item.account_holder_name ? item.account_holder_name :''
            } */
      name = item.account_holder_name ? item.account_holder_name : "";
    } else {
      name = item.account_holder_name ? item.account_holder_name : "";
    }
    let nombre = name.indexOf(" ", 1);
    let final = name.charAt(1) + name.charAt(nombre + 1);

    return name;
  };

  const copy = (text) => {
    var id = "el-id-del-textarea";
    var existsTextarea = document.getElementById(id);

    if (!existsTextarea) {
      var textarea = document.createElement("textarea");
      textarea.id = id;
      // Coloca el textarea en el borde superior izquierdo
      textarea.style.position = "fixed";
      textarea.style.top = 0;
      textarea.style.left = 0;

      // Asegurate que las dimensiones del textarea son minimas, normalmente 1px
      // 1em no funciona porque esto generate valores negativos en algunos exploradores
      textarea.style.width = "1px";
      textarea.style.height = "1px";

      // No se necesita el padding
      textarea.style.padding = 0;

      // Limpiar bordes
      textarea.style.border = "none";
      textarea.style.outline = "none";
      textarea.style.boxShadow = "none";

      // Evitar el flasheo de la caja blanca al renderizar
      textarea.style.background = "transparent";
      document.querySelector("body").appendChild(textarea);

      existsTextarea = document.getElementById(id);
    } else {
    }

    existsTextarea.value = text;
    existsTextarea.select();

    try {
      var status = document.execCommand("copy");
      if (!status) {
        console.error("No se pudo copiar el texto");
      } else {
        setCopiar(true);
        setIdCopiado(text);
      }
    } catch (err) { }
  };
  function formatDate(x) {
    let currentTimestamp = x - 18000000;
    // get current timestamp
    /* let date = new Intl.DateTimeFormat('en-US', { year: 'numeric', month: '2-digit', day: '2-digit', hour: '2-digit', minute: '2-digit' }).format(currentTimestamp) */
    let date = moment(x).format("DD/MM/YYYY HH:mm A");

    return date;
  }

  const changeTextState = (state) => {
    let text = "";

    if (state.state === 0) {
      text = "Pendiente";
    } else if (state.state === 1) {
      text = "En proceso";
    } else if (state.state === 2) {
      text = `Depositado  ${state.bank_dispersion !== null &&
        state.bank_dispersion !== undefined &&
        state.bank_dispersion !== ""
        ? `por ${state.bank_dispersion}`
        : ""
        }`;
    } else if (state.state === 3) {
      text = `Consignado  ${state.bank_dispersion !== null &&
        state.bank_dispersion !== undefined &&
        state.bank_dispersion !== ""
        ? `por ${state.bank_dispersion}`
        : ""
        }`;
    } else if (state.state === 4) {
      text = "Hubo un problema";
    } else if (state.state === 5) {
      text = "Rechazado por el banco";
    } else if (state.state === 6) {
      text = "Fraude";
    }
    return text;
  };
  const changeBgColor = (state) => {
    let color = "";
    if (state === 0) {
      color = "bg-yellow-500";
    } else if (state === 1) {
      color = "bg-yellow-500";
    } else if (state === 2) {
      color = "bg-green-500";
    } else if (state === 3) {
      color = "orange";
    } else if (state === 4) {
      color = "Color-red";
    } else if (state === 5) {
      color = "Color-red";
    } else if (state === 6) {
      color = "Color-red";
    } else if (state === 7) {
      color = "Color-red";
    }
    return color;
  };
  const getDate = (data) => {
    let date = data.date_created;
    if (data.state === 0) {
      date = data.date_created;
    } else if (data.state === 1) {
      date = data.date_created;
    } else if (data.state === 2) {
      date = data.date_deposited;
    } else if (data.state === 3) {
      date = data.date_consigned;
    } else if (data.state === 4) {
      date = data.date_rejected;
    } else if (data.state === 5) {
      date = data.date_rejected;
    } else if (data.state === 6) {
      date = data.date_rejected;
    }
    return date;
  };
  const ActivarModal = (data) => {
    setDataModal(data);
    setOpenModal(true);
  };

  const columns = [
    {
      name: "REF",
      cell: (row) => (
        <div className={"flex-row flex items-center justify-start"}>
          <div
            title={
              row.state === 0 || row.state === 1
                ? "Pendiente"
                : row.state === 2
                  ? "Depositado"
                  : row.state === 3
                    ? "Consignado"
                    : row.state === 4 || row.state === 5 || row.state === 6
                      ? "Rechazado"
                      : row.state === 7
                        ? "Reversado"
                        : ""
            }
            className={
              changeBgColor(row.state) + " rounded-full h-4 w-4 bg-green mr-3"
            }
          ></div>
          <div
            className="links cursor-pointer"
            onClick={() => ActivarModal(row)}
            style={{ fontWeight: 700 }}
          >
            {row.pk}
          </div>
        </div>
      ),
      sortable: true,
    },
    {
      name: "BANCO",
      //selector: 'bank_account',
      sortable: true,
      cell: (row) => (
        <button className="button-outline-off ">
          <p className="">
            {row.account_bank !== undefined ? row.account_bank : row.bank_name}{" "}
            {(row.account_state === 1 || row.account_state === 0) &&
              row.state !== 2
              ? " - "
              : ""}
          </p>
          <div
            className={
              row.account_state === 0
                ? "resaltado-creados "
                : row.account_state === 1
                  ? "resaltado-proceso"
                  : ""
            }
          >
            {row.account_state !== undefined
              ? row.account_state === 0
                ? "Inscribir "
                : row.account_state === 1 && row.state === 3
                  ? "En proceso"
                  : row.account_state === 1 &&
                    (row.state === 0 || row.state === 1)
                    ? "Inscribiendo"
                    : ""
              : ""}
          </div>
        </button>
      ),
    },

    {
      // name: 'NOMBRE',
      // //selector: 'name',
      // cell: row => (
      //     /* row.payer_name ?  row.payer_name : */ row.account_holder_name ? row.account_holder_name  : ''
      // ),
      // sortable: true,

      name: "NOMBRE",
      selector: "name",
      cell: (row) => (
        <div>
          {row.type !== "Retiro" && (
            <p className="lower">
              {row.account_holder_name && row.owner_first_name
                ? row.account_holder_name + " -"
                : ""}
            </p>
          )}
          <div className="flex flex-row">
            {(row.owner_state === 3 || row.owner_state === 4) && (
              <img className="mr-1" src={approve} width={10} height={10} />
            )}
            {row.owner_state < 3 && (
              <img className="mr-1" src={cancel} width={10} height={10} />
            )}
            <a
              style={{ color: "#007bff" }}
              target="_blank"
              href={`${DEV}admin/api/profile/${row.owner_id}/change/`}
            >
              {cHangeName(row)}
            </a>
          </div>
        </div>
      ),
    },
    /* {
        name: 'MONTO',
        cell: row => <div>{FormatAmount(row.amount)}</div>,
        sortable: true,
    }, */
    {
      name: "A DISPERSAR",
      cell: (row) => (
        <div>
          <button
            className="button-outline-off copy-cursor"
            onClick={() => copy(row.payed_amount)}
          >
            <span>
              {` ${FormatAmount(row.payed_amount, 1)}`}
              <span style={{ fontSize: 9 }}>{`${FormatAmount(
                row.payed_amount,
                2
              )}`}</span>
            </span>
          </button>
          {copiar && row.payed_amount === idCopiado && (
            <div className="copiado">Copiado</div>
          )}
        </div>
      ),
      sortable: true,
    },
    {
      name: "FECHA",
      cell: (row) => (
        <div>
          {
            /* row.state === 0 ? moment(getDate(row) -18000000).format('YYYY-MM-DD HH:mm:ss A'): */ formatDate(
            getDate(row)
          )
          }
        </div>
      ),
      //cell: row => <div>{moment(row.date).format('YYYY-MM-DD HH:mm:ss A')}</div>,
      sortable: true,
    },

    {
      name: "REF",
      //selector: 'state',
      cell: (row) => (
        <div>
          {" "}
          {row.state !== undefined
            ? `${changeTextState(row)}
                     ${row.type == "Retiro" && row.bank_dispersion === ""
              ? "por "
              : ""
            }`
            : ""}
        </div>
      ),
      sortable: true,
    },

    /* {
                    name: 'TYPE',
          //selector: 'state_bank',
          cell: row => (
                row.type !== undefined ?
                row.type : ''
                ),
                sortable: true,
        }, */
  ];
  const removeNullValues = obj => {
    Object.keys(obj).forEach(key => {
      if (obj[key] === null) {
        delete obj[key];
      }
    });
    return obj;
  };


  const fetchData = async (currentFilters = activeFilter.filters, page = 1, perPageDataTable = perPage) => {
    try {
      setIsLoading(true);
      setPending(true);

      // Combinar los filtros actuales con los nuevos que puedan llegar como parámetros
      let filterRequest = { ...currentFilters };
      filterRequest = removeNullValues(filterRequest)
      // Actualizar el número de página y las filas por página

      // Hacer la solicitud a la API pasando el número de página y cantidad de filas
      const response = await AxioInstance.post(`/api/list/withdraw`, {
        headers: await getAuthenticatedHeaders(),
        pageNum: page,  // Usamos el número de página actual
        perPage: perPageDataTable,
        ...filterRequest
      });

      if (response.data.status === 200) {
        setTotalRows(response.data.data.count);

        // Procesar los datos recibidos
        const pendings = response.data.data.data;
        let final = _.sortBy(pendings, ["date_created"]).reverse();

        if (activeFilter.filterName === "#inscritos") {
          let terminado = [];
          final.forEach((item) => {
            if (item.account_bank !== 18) {
              terminado.push(item);
            }
          });
          setDataList(terminado);
        } else {
          setDataList(final);
        }
        setPending(false);
      }

      setIsLoading(false);
      setPending(false);
    } catch (error) {
      console.error('Error fetching data:', error);
      setIsLoading(false);

      setPending(false);
    }
  };


  const handleChange = async ({ selectedRows }) => {
    if (selectedRows.length === 0) {
      setHeader(true);
    } else {
      setHeader(false);
    }

    setData(selectedRows);
  };
  const handleChangeSelect = (value) => {
    setLabel(value);
  };
  const sendProcessBackend = async () => {
    if (label === "download_bank_payment_file_of_selected") {
      if (data.length > 0) {
        //downloadCSV(data);
        downloadFileBankPaymentSelected(data);
      } else {
        setError(true);
      }
    } else if (label === "bulk_deposit_nequi") {
      setBancos("Nequi");
      setLabel("bulk_deposit_nequi");
      DispersionNequi(data);
    } else if (label === "download_bank_payment_file_of_all") {
      downloadFileBankPaymentAll(data);
    } else if (
      label === "bulk_deposit" ||
      label === "bulk_consigned" ||
      label === "fraud_reject"
    ) {
      if (data.length > 0) {
        updateStatusWithdraw(data);
      } else {
        setError(true);
      }
    } else if (label === "bulk_deposit_Bancolombia") {
      setBancos("Bancolombia");
      setLabel("bulk_deposit");
    } else if (label === "bulk_deposit_BBVA") {
      setBancos("BBVA");
      setLabel("bulk_deposit");
    } else if (label === "bulk_deposit_Avvilla") {
      setBancos("avvilla");
      setLabel("bulk_deposit");
    } else if (label === "bulk_deposit_Davivienda") {
      setBancos("davivienda");
      setLabel("bulk_deposit");
    } else if (label === "bulk_deposit_Depósito") {
      setBancos("Depósito");
      setLabel("bulk_deposit");
    } else if (label === "bulk_consigned_Bancolombia") {
      setBancos("Bancolombia");
      setLabel("bulk_consigned");
    } else if (label === "bulk_consigned_Avvilla") {
      setBancos("avvilla");
      setLabel("bulk_consigned");
    } else if (label === "bulk_consigned_Davivienda") {
      setBancos("davivienda");
      setLabel("bulk_consigned");
    } else if (label === "bulk_consigned_BBVA") {
      setBancos("BBVA");
      setLabel("bulk_consigned");
    } else if (label === "bulk_consigned_Depósito") {
      setBancos("Depósito");
      setLabel("bulk_consigned");
    } else if (label === "bulk_reject_bancolombia") {
      setBancos("Bancolombia");
      setLabel("bulk_reject");
    } else if (label === "bulk_reject_davivienda") {
      setBancos("Davivienda");
      setLabel("bulk_reject");
    } else if (label === "bulk_reject_bbva") {
      setBancos("Bbva");
      setLabel("bulk_reject");
    } else if (label === "bulk_reject_avvillas") {
      setBancos("Avvillas");
      setLabel("bulk_reject");
    } else if (label === "bulk_deposit_cobre_bancolombia") {
      ///alert('cobre')
      setLabel("bulk_deposit_cobre_bancolombia");
      dispersionCobreBancolombia(data);
    } else if (label === "bulk_deposit_cobre") {
      ///alert('cobre')
      setLabel("bulk_deposit_cobre");
      dispersionCobre(data);
    }
    if (label === "bulk_deposit_cobre_colurs") {
      ///alert('cobre')
      setLabel("bulk_deposit_cobre_colurs");
      dispersionCobreBrand(data, 'COLURS');
    } else if (label === "retired_bank_pagosgde") {
      sendPagosGDE(data);
    } /* else if (label === 'inscription') {
                    inscriptonsBank(data)
                } */ else {
      setMsn("Ninguna opción seleccionada");
    }
  };

  const updateStatusWithdraw = async (data) => {
    setActivo(false);
    setLoadingGDE(true);

    /*  let pks = [];
         _.filter(data, function(o){
             pks.push(parseInt(o.pk))           
             return (parseInt(o.pk))
         });
     

         data = {
             pks: pks,
             selected: true,
             status: label,
             headers : await getAuthenticatedHeaders(),
     
         } */
    let pks = [];
    _.filter(data, (array) => {
      pks.push({
        pk: parseInt(array.pk),
        type:
          array.type === "Retiro a tercero"
            ? "third_party_withdraw"
            : "withdraw",
      });
    });

    data = {
      data: pks,
      status: label,
      /* selected: true */
      bank_dispersion: bancos,
      platform: "PANEL",
      headers: await getAuthenticatedHeaders(),
    };

    try {
      const response = await AxioInstance.post(
        `/api/withdraw/update_status_withdraw`,
        data
      );

      if (response.data.status === 200) {
        applyFilterByName(window.location.hash)

        /* setReloadRetiros(!reloadRetiros)
                setLabel('') */
      } else {
        setErrorWithdraws(true);
        setShowBanner(true);
      }
    } catch (error) {
      setIsLoading(true);
      setErrorWithdraws(true);
      setShowBanner(true);
    }
    setBancos("");
    setLabel("");
    setToggledClearRows(true);
    setActivo(true);
    setLoadingGDE(false);
  };
  const updateStatusWithdrawReject = async (data) => {
    setActivo(false);
    setLoadingGDE(true);

    let pks = [];
    _.filter(data, (array) => {
      pks.push({
        pk: parseInt(array.pk),
        type:
          array.type === "Retiro a tercero"
            ? "third_party_withdraw"
            : "withdraw",
      });
    });

    data = {
      data: pks,
      status: label,
      /* selected: true */
      bank_dispersion: bancos,
      headers: await getAuthenticatedHeaders(),
    };

    try {
      const response = await AxioInstance.post(
        `/api/withdraw/reject_status_withdraw`,
        data
      );

      if (response.data.status === 200) {
        applyFilterByName(window.location.hash)

        /* setReloadRetiros(!reloadRetiros)
                setLabel('') */
      } else {
        setErrorWithdraws(true);
        setShowBanner(true);
      }
    } catch (error) {
      setIsLoading(true);
      setErrorWithdraws(true);
      setShowBanner(true);
    }
    setBancos("");
    setLabel("");
    setToggledClearRows(true);
    setActivo(true);
    setLoadingGDE(false);
  };


  const DispersionNequi = async (data) => {
    setLoadingGDE(true);
    setActivo(false);
    let pks = [];
    _.filter(data, (array) => {
      pks.push(parseInt(array.pk));
    });
    /*  _.filter(data, function(o){
                    pks.push(parseInt(o.pk))           
             return (parseInt(o.pk))
         }); */
    data = {
      pks: pks,
      headers: await getAuthenticatedHeaders(),
    };

    try {
      const response = await AxioInstance.post(
        `/api/withdraw/dispersar_to_nequi`,
        data
      );
      if (response.data.status === 200) {
        setShowNequi(true);
        setResultsNequi(response.data.data);
      } else {
        setErrorWithdraws(true);
        setShowBanner(true);
      }
      applyFilterByName(window.location.hash)

    } catch (error) {
      setIsLoading(true);
      setErrorWithdraws(true);
      setShowBanner(true);
      applyFilterByName(window.location.hash)

    }
    setBancos("");
    setLabel("");
    setToggledClearRows(true);
    setActivo(true);
    setLoadingGDE(false);
  };
  const dispersionCobreBrand = async (data, brand = null) => {
    setLoadingGDE(true);
    setActivo(false);
    let pks = [];
    _.filter(data, (array) => {
      pks.push(parseInt(array.pk));
    });
    /*  _.filter(data, function(o){
                    pks.push(parseInt(o.pk))           
             return (parseInt(o.pk))
         }); */
    data = {
      pks: pks,
      headers: await getAuthenticatedHeaders(),
      brand
    };
    try {
      const response = await AxioInstance.post(
        `/api/withdraw/dispersar_to_cobre_bancolombia`,
        data
      );
      if (response.data.status === 200) {
        setShowBannerCobre(true);
        setdescriptionBannerCobre(response?.data?.data?.data);
      } else {
        setErrorWithdraws(true);
        setShowBanner(true);
      }
      applyFilterByName(window.location.hash)

    } catch (error) {
      setIsLoading(true);
      setErrorWithdraws(true);
      setShowBanner(true);
      applyFilterByName(window.location.hash)

    }
    setBancos("");
    setLabel("");
    setToggledClearRows(true);
    setActivo(true);
    setLoadingGDE(false);
  };
  const dispersionCobreBancolombia = async (data) => {
    setLoadingGDE(true);
    setActivo(false);
    let pks = [];
    _.filter(data, (array) => {
      pks.push(parseInt(array.pk));
    });
    /*  _.filter(data, function(o){
                    pks.push(parseInt(o.pk))           
             return (parseInt(o.pk))
         }); */
    data = {
      pks: pks,
      headers: await getAuthenticatedHeaders(),
    };
    console.log(JSON.stringify(data));
    try {
      const response = await AxioInstance.post(
        `/api/withdraw/dispersar_to_cobre_bancolombia`,
        data
      );
      console.log("dispersar_to_cobre_bancolombia:", response);
      if (response.data.status === 200) {
        setShowBannerCobre(true);
        setdescriptionBannerCobre(response?.data?.data?.data);
      } else {
        setErrorWithdraws(true);
        setShowBanner(true);
      }
      applyFilterByName(window.location.hash)

    } catch (error) {
      setIsLoading(true);
      setErrorWithdraws(true);
      setShowBanner(true);
      applyFilterByName(window.location.hash)

    }
    setBancos("");
    setLabel("");
    setToggledClearRows(true);
    setActivo(true);
    setLoadingGDE(false);
  };
  const dispersionCobre = async (data) => {
    setLoadingGDE(true);
    setActivo(false);
    let pks = [];
    _.filter(data, (array) => {
      pks.push(parseInt(array.pk));
    });
    /*  _.filter(data, function(o){
                    pks.push(parseInt(o.pk))           
             return (parseInt(o.pk))
         }); */
    data = {
      pks: pks,
      headers: await getAuthenticatedHeaders(),
    };
    console.log(JSON.stringify(data));
    try {
      const response = await AxioInstance.post(
        `/api/withdraw/dispersar_to_cobre`,
        data
      );
      if (response.data.status === 200) {
        setShowBannerCobre(true);
        setdescriptionBannerCobre(response?.data?.data?.data);
      } else {
        setErrorWithdraws(true);
        setShowBanner(true);
      }
      applyFilterByName(window.location.hash)

    } catch (error) {
      setIsLoading(true);
      setErrorWithdraws(true);
      setShowBanner(true);
      applyFilterByName(window.location.hash)

    }
    setBancos("");
    setLabel("");
    setToggledClearRows(true);
    setActivo(true);
    setLoadingGDE(false);
  };

  const downloadFileBankPaymentSelected = async (data) => {
    setLoadingGDE(true);
    setActivo(false);
    let pks = [];
    _.filter(data, (array) => {
      pks.push(parseInt(array.pk));
    });
    /*  _.filter(data, function(o){
                    pks.push(parseInt(o.pk))           
             return (parseInt(o.pk))
         }); */
    data = {
      data: pks,
      /* selected: true,
            status: label, */
      bank:
        window.location.hash === "#otrosbancos"
          ? "avvillas"
          : window.location.hash === "#bancolombia" ||
            window.location.hash === "#trixel"
            ? "bancolombia"
            : window.location.hash === "#nequi"
              ? "nequi"
              : window.location.hash === "#ezytransfer"
                ? "ezytransfer"
                : "",
      headers: await getAuthenticatedHeaders(),
    };

    try {
      const response = await AxioInstance.post(
        `/api/withdraw/download_file_bankpayment_selected`,
        data
      );
      if (response.data.status === 200) {
        setDispersion(response?.data?.data);
        setShowBannerDownload(true);
        setRenderDownload(!renderDownload);
        //setReloadRetiros(!reloadRetiros)
        //setLabel('')
        //handleClearRows()
        /* setTimeout(() => {
                    window.location.reload()
                }, 800); */
      }
    } catch (error) {
      setIsLoading(true);
    }
    setBancos("");
    setLabel("");
    setToggledClearRows(true);
    setActivo(true);
    setLoadingGDE(false);
  };
  const downloadTxt = async (data) => {
    setActivo(false);

    const link = document.createElement("a");
    if (data == null) return;

    const filename = "pago_dispersion.txt";
    link.setAttribute(
      "href",
      "data:text/plain;charset=utf-8," + encodeURIComponent(data)
    );
    link.setAttribute("download", filename);
    link.click();
    setReloadRetiros(!reloadRetiros);
    //window.location.reload();
    setBancos("");
    setLabel("");
    setToggledClearRows(true);
    setActivo(true);
  };
  const downloadFileBankPaymentAll = async (data) => {
    setLoadingGDE(true);

    setActivo(false);
    let pks = [];
    _.filter(data, function (o) {
      pks.push(parseInt(o.pk));
      return parseInt(o.pk);
    });

    let dataTo = {
      pks: pks,
      /* selected: true,
            status: label, */
      headers: await getAuthenticatedHeaders(),
    };
    try {
      const response = await AxioInstance.post(
        `/api/withdraw/download_file_bankpayment_all`,
        dataTo
      );
      if (response.data.status === 200) {
        setDispersion(response.data.data);
        downloadTxt(response.data.data);
        //setReloadRetiros(!reloadRetiros)
        //handleClearRows()
        setTimeout(() => {
          window.location.reload();
        }, 800);
      }
    } catch (error) {
      console.log("🚀 ~ downloadFileBankPaymentAll ~ error:", error)

      setIsLoading(true);
    }

    /* try {
            const response = await axios.get(`/api/withdraw/download_file_bankpayment_all`, {
                    headers : await getAuthenticatedHeaders(),
            });
     
                if (response.data.status === 200) {

                setDispersionAll(response.data.data);
                downloadTxt(response.data.data);
            }
        } catch (error) {

                setIsLoading(true);
        } */
    setBancos("");
    setLabel("");
    setToggledClearRows(true);
    setActivo(true);
    setLoadingGDE(false);
  };

  const sendPagosGDE = async (row) => {
    setActivo(false);
    setLoadingGDE(true);
    let pks = [];
    _.filter(row, (array) => {
      pks.push(parseInt(array.pk));
    });
    const data = {
      bank_dispersion: "powwi",
      withdraws: pks,
      name_device: browserName,
      system_operative: "WEB",
    };

    try {
      const response = await AxioInstance.post(`/api/withdraw/bank_pagosgde`, {
        data,
        headers: await getAuthenticatedHeaders(),
      });

      if (response.status === 200) {
        setInfoWithdraws(response.data.data);
        setShowBanner(true);
        setLoadingGDE(false);
        setReloadData(true);
      } else {
        setErrorWithdraws(true);
        setShowBanner(true);
        setLoadingGDE(false);
      }
      setLoadingGDE(false);
    } catch (error) {
      setLoadingGDE(false);
      setInfoWithdraws(null);
      setShowBanner(false);
      setErrorWithdraws(true);
    }
    setTimeout(() => {
      applyFilterByName(window.location.hash)

    }, 5000);
    setBancos("");
    setLabel("");
    setToggledClearRows(true);
    setActivo(true);
  };

  const paginationOptions = {
    rowsPerPageText: "Filas por página",
    rangeSeparatorText: "de",
    selectAllRowsItem: true,
    selectAllRowsItemText: "Todos",
  };

  const Circular = () => (
    <div style={{ padding: "24px" }}>
      {isLoading && <CircularProgress color="red" size={75} />}
    </div>
  );


  const contextActions = () => (
    <>
      <div className="flex md:flex-row text-left">
        <p className="mr-5 mt-1">
          ${new Intl.NumberFormat(["ban", "id"]).format(amountCheck)}
        </p>
        <div className="w-full md:w-3/4 mr-2 input-borders ">
          <NativeSelect
            className="input-border-green background-white "
            id="demo-customized-select-native"
            defaultValue={label}
            value={label}
            onChange={(e) => handleChangeSelect(e.target.value)}
          >
            <option aria-label="None" disabled value="">
              Escoge una acción
            </option>
            <option disabled>--------------------------------------</option>
            <option value={"download_bank_payment_file_of_selected"}>
              Generar archivo para Dispersión
            </option>
            <option disabled>--------------------------------------</option>
            {/* <option value={"download_bank_payment_file_of_all"}>Dispersion de pago de todos</option> */}
            {/*                 <option value={"bulk_deposit"}>Depositar</option>
                        <option value={"bulk_consigned"}>Consignar</option> */}
            <option value={"bulk_consigned_Avvilla"}>
              Consignado por AV Villas
            </option>
            <option value={"bulk_consigned_Davivienda"}>
              Consignado por Davivienda
            </option>

            <option value={"bulk_consigned_Bancolombia"}>
              Consignado por Bancolombia
            </option>
            <option value={"bulk_consigned_BBVA"}>Consignado por BBVA</option>
            {/* <option value={"bulk_consigned_Depósito"}>Consignado por Depósito</option> */}
            <option disabled>--------------------------------------</option>
            <option value={"retired_bank_pagosgde"}>
              Dispersar por Depósito
            </option>
            <option disabled>--------------------------------------</option>
            <option value={"bulk_deposit_nequi"}>Dispersar por Nequi</option>
            <option disabled>--------------------------------------</option>
            <option value={"bulk_deposit_cobre"}>
              Dispersar por saldo Cobre
            </option>
            <option value={"bulk_deposit_cobre_colurs"}>
              Dispersar por saldo Cobre (Colurs)
            </option>
            <option value={"bulk_deposit_cobre_bancolombia"}>
              Dispersar por Cobre (Bancolombia)
            </option>
            <option disabled>--------------------------------------</option>
            <option value={"bulk_deposit_Avvilla"}>
              Depositado por AV Villas
            </option>
            <option value={"bulk_deposit_Davivienda"}>
              Depositado por Davivienda
            </option>
            <option value={"bulk_deposit_Bancolombia"}>
              Depositado por Bancolombia
            </option>
            <option value={"bulk_deposit_BBVA"}>Depositado por BBVA</option>
            {/* <option value={"bulk_deposit_Depósito"}>Depositado por Depósito</option> */}
            <option disabled>--------------------------------------</option>
            <option value={"bulk_reject_bancolombia"}>
              Rechazado por Bancolombia
            </option>
            <option value={"bulk_reject_davivienda"}>
              Rechazado por Davivienda
            </option>
            <option value={"bulk_reject_bbva"}>Rechazado por Bbva</option>
            <option value={"bulk_reject_avvillas"}>
              Rechazado por Avvillas
            </option>

            <option disabled>--------------------------------------</option>
            <option value={"fraud_reject"}>Fraude</option>
            {/* <option value={"inscription"}>Inscripcion</option> */}
          </NativeSelect>
        </div>
        <div className="w-full md:w-1/3 pl-2 ">
          {label !== "" && activo ? (
            <button
              className="py-1 px-4 flex bg-green-600 hover:bg-green-600 border-green-600 hover:green-600 text-sm border-4 text-white  rounded-full button-outline-off"
              type="button"
              onClick={sendProcessBackend}
            >
              {loadingGDE ? "Procesando..." : "Aplicar"}
            </button>
          ) : (
            <button
              className="py-1 px-4 flex  text-sm border-4 text-white rounded-full btn-retiros button-outline-off bloqued-cursor"
              type="button"
            >
              {loadingGDE ? "Procesando..." : "Aplicar"}
            </button>
          )}
        </div>
      </div>
    </>
  );




  useEffect(() => {
    if (window.location.hash !== "#consignado") {
      setfilterBankName(null);
    }
  }, [window.location.hash]);
  useEffect(() => {
    if (!showBannerCobre) {
      setdescriptionBannerCobre("");
    }
  }, [showBannerCobre]);



  useEffect(() => {
    console.log('active filter:', activeFilter)
    const keysFilterConsignado = Object.keys(filtersConsignado)

    if (activeFilter.filterName === 'consignados' && !activeFilterConsignados) {
      setActiveFilterConsignados(activeFilter)
    }

    if (activeFilter.filterName !== 'consignados' && !keysFilterConsignado.includes(activeFilter.filterName)) {
      setActiveFilterConsignados(null)

    }
  }, [activeFilter])

  const filterButtonsConfig = [
    { filterName: '#enproceso', filters: { state: 1 }, label: 'En proceso' },
    { filterName: '#bancolombia', filters: { bank: 0, exclude_autopilot: true, state: 0, status_account: 2 }, label: 'Bancolombia', showAllMovements: true },
    { filterName: '#bancolombiacobre', filters: { bank: 0, state: 0 }, label: 'Bancolombia Cobre' },
    { filterName: '#consignadoscobre', filters: { dispersion_cobre: true, state: 3 }, label: 'Consignados Cobre' },
    { filterName: '#davivienda', filters: { bank: 3, state: 0 }, label: 'Davivienda' },
    { filterName: '#grupoaval', filters: { bank: 6, state: 0 }, label: 'Grupo Aval' },
    { filterName: '#bbva', filters: { bank: 4, state: 0 }, label: 'BBVA' },
    { filterName: '#otrosbancos', filters: { bank: 1, state: 0 }, label: 'Otros bancos', showAllMovements: true },
    { filterName: '#ezytransfer', filters: { state: 0, filterBrand: 'EZY_TRANSFER' }, label: 'Ezytransfer' },
    { filterName: '#bancolombiaetsas', filters: { bank: 0, exclude_autopilot: true, state: 0, username_trixel: 0, status_account: 2 }, label: 'Bancolombia E.T SAS' },
    { filterName: '#daviviendaetsas', filters: { bank: 3, state: 0, username_trixel: 0 }, label: 'Davivienda E.T SAS' },

  ];
  const filtersPermission_cobru = [
    { filterName: '#todos', filters: {}, label: 'Todos' },
    { filterName: '#pendientes', filters: { state: 0 }, label: 'Pendientes' },
    { filterName: '#consignados', filters: { state: 3 }, label: 'Consignados' },
    { filterName: '#depositados', filters: { state: 2 }, label: 'Depositados' },
    { filterName: '#rechazados', filters: { state: 5 }, label: 'Rechazados' },
    { filterName: '#sininscribir', filters: { state: 0, status_account: 0 }, label: 'Sin Inscribir' },
    { filterName: '#inscribiendo', filters: { state: 0, status_account: 1 }, label: 'Inscribiendo' },
    { filterName: '#inscritos', filters: { state: 0, status_account: 2 }, label: 'Inscritos' },
    { filterName: '#fraudes', filters: { state: 6 }, label: 'Fraudes' },
  ]

  const filtersConsignado = [
    { filterName: '#consignados', filters: { state: 3 }, label: 'Todos' },
    { filterName: '#consignados_bancolombia', filters: { state: 3, bank_name: 0 }, label: 'Bancolombia' },
    { filterName: '#consignados_avvillas', filters: { state: 3, bank_name: 12 }, label: 'Av Villas' },
    { filterName: '#consignados_bbva', filters: { state: 3, bank_name: 3 }, label: 'BBVA' },
    { filterName: '#consignados_davivienda', filters: { state: 3, bank_name: 2 }, label: 'Davivienda' },
  ]

  const allFiltersList = [...filterButtonsConfig, ...filtersPermission_cobru, ...filtersConsignado]

  const setHashFilter = (filtername) => {
    history.push(`/retiros${filtername}`)
  }

  const applyFilterByName = (filterName) => {
    const filterConfig = allFiltersList.find(f => f.filterName === filterName);

    if (filterConfig) {
      const saveFilters = { ...defaultFilters, ...filterConfig.filters };
      const perPageToUse = filterConfig.showAllMovements ? 99999999 : perPage;

      // Actualizar el estado del filtro activo

      setActiveFilter({ filterName, filters: saveFilters });

      // Llamada a fetchData con los filtros encontrados
      fetchData(saveFilters, 1, perPageToUse);

      // Si el filtro es de tipo "selector", puedes manejarlo de manera diferente si es necesario
      if (filterConfig.type === 'selector') {
        console.log(`Filtro aplicado desde un selector: ${filterName}`);
        // Realiza lógica adicional para selectores si es necesario
      }
    } else {
      console.log(`Filtro no encontrado: ${filterName}`);
    }
  };



  const FilterButton = ({ filterName, label, filters, showAllMovements = false }) => {

    const isActive = activeFilter.filterName === filterName;

    return (
      <button
        className={
          `focus:outline-none hover:bg-${variablesDomain.classNameColor} text-white font-bold py-1 px-4 rounded-full h-8 mr-1 mt-3 ` +
          (isActive ? `bg-${variablesDomain.classNameColor}` : "bg-gray-500")
        }
        onClick={() => {
          if (isActive) {
            applyFilterByName(window.location.hash)
          } else {
            setHashFilter(filterName)
          }
        }}
        key={filterName}
        value={filterName}
      >
        <span style={{ display: "flex", width: "max-content" }}>{label}</span>
      </button>
    );
  };



  const FilterSelect = ({ filterName, filtersList = [], options, defaultValue, value, setactivefilter = setActiveFilter }) => {

    const isActive = activeFilter.filterName === filtersList.find(item => item.filterName === activeFilter.filterName)?.filterName;

    return (
      <div className="custom-select">
        <select
          name={filterName}
          value={value}
          className={
            `focus:outline-none hover:bg-${variablesDomain.classNameColor} text-white font-bold py-1 px-4 rounded-full h-8 mr-1 mt-3 ` +
            (isActive ? `bg-${variablesDomain.classNameColor}` : "bg-gray-500")
          }
          onChange={(e) => {
            const selectedValue = e.target.value;
            setHashFilter(selectedValue)
          }}
        >

          {filtersList.map(({ filterName, label }, index) => (
            <option key={index} value={filterName}>
              {label}
            </option>
          ))}
        </select>
      </div>
    );
  };
  useEffect(() => {
    if (window.location.hash === '') {
      setHashFilter('#pendientes')
      /* applyFilterByName('todos') */

    } else {
      applyFilterByName(window.location.hash)
    }
  }, [window.location.hash])
  return (
    <>


      {
        showFilters
          ?
          <div className="flex flex-row text-xs p-1 overflow-auto">
            {activeFilter.filterName === '#consignados' || filtersConsignado.map(item => item.filterName).includes(activeFilter.filterName) ? (
              <FilterSelect
                value={activeFilter.filterName}
                filterName="bankNameConsignado"
                filtersList={filtersConsignado}

              />
            ) : null}

            <FilterSelect
              value={filtersConsignado.map(item => item.filterName).includes(activeFilter.filterName) ? '#consignados' : activeFilter.filterName}
              filterName="permission_cobru"
              filtersList={filtersPermission_cobru}

            />
            {filterButtonsConfig.map(({ filterName, filters, label, showAllMovements }) => (
              <FilterButton
                key={filterName}
                filterName={filterName}
                filters={filters}
                label={label}
                showAllMovements={showAllMovements}
              />
            ))}

          </div>
          :
          null
      }

      {openModal === true && (
        <ModalDescripcion
          modal={() => setOpenModal()}
          open={openModal}
          data={dataModal}
        />
      )}
      {/*datatble*/}

      <div className="flex md:flex-row flex-wrap p-2">
        <div className="w-full mt-5 ">
          {showBanner && infoWithdraws && (
            <Banner
              title={"Proceso realizado"}
              description={`Problemas con el banco ${infoWithdraws.total_prob_bank} se depositaron ${infoWithdraws.total_approved} retiros seleccionado se rechazaron ${infoWithdraws.total_rejected} retiros seleccionados`}
              setShowBanner={setShowBanner}
              error={false}
            />
          )}
          {showNequi && (
            <Banner
              title={"Proceso realizado"}
              description={`aprobados: ${ResultsNequi.total_approved} - rechazados: ${ResultsNequi.total_rejected}`}
              setShowBanner={setShowNequi}
              error={false}
            />
          )}
          {showBannerDownload && (
            <Banner
              title={"Proceso realizado"}
              description={
                `El archivo de dispersión se está procesando. ` /* Le avisaremos por correo cuando este listo. */
              }
              setShowBanner={setShowBannerDownload}
              error={false}
            />
          )}
          {showBannerCobre && (
            <Banner
              title={"Proceso realizado"}
              description={descriptionBannerCobre}
              setShowBanner={setShowBannerCobre}
              error={false}
            />
          )}
          {showBanner && errorWithdraws && (
            <Banner
              title={"Proceso realizado"}
              description={`Ah ocurrido un error, por favor intentelo mas tarde`}
              setShowBanner={setShowBanner}
              error={errorWithdraws}
            />
          )}

          <DataTable
            title={``}
            noHeader={header}
            clearSelectedRows={toggledClearRows}
            data={dataList}
            columns={columns}
            selectableRows={((others && !isSuperUser) || !permittedActions) ? false : true}
            selectableRowsHighlight={permittedActions ? true : false}
            fixedHeader={true}
            noDataComponent={"No hay movimientos disponibles"}
            pagination={pagination}
            paginationComponentOptions={paginationOptions}
            progressPending={pending}
            progressComponent={<Circular />}
            onSelectedRowsChange={handleChange}
            contextActions={permittedActions ? contextActions() : false}
            /* actions={contextActions(label)} */
            paginationTotalRows={totalRows}
            paginationPerPage={newPerPage}
            paginationServer
            paginationRowsPerPageOptions={numFilas}
            paginationResetDefaultPage={toggledClearRows}
            onChangeRowsPerPage={(perPage) => {
              /*               setperPage(perPage)
              
               */
              const filterFetch = activeFilterConsignados ? activeFilterConsignados?.filters : activeFilter.filters
              fetchData(filterFetch, 1, perPage);  // Restablecer a la primera página cuando cambian las filas por página
              setToggledClearRows(!toggledClearRows)
            }}
            onChangePage={(page) => {
              /*               setPage(page)
              
               */
              const filterFetch = activeFilterConsignados ? activeFilterConsignados?.filters : activeFilter.filters
              console.log("🚀 ~ NewRetirosRefactory ~ filterFetch:", filterFetch)

              fetchData(filterFetch, page);  // Cambiar de página manteniendo los filtros actuales
            }}

          />
        </div>
      </div>
    </>
  );
};

export default NewRetirosRefactory;
